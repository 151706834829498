import { useDispatch, useSelector } from 'react-redux'
import { Grid, Box } from '@material-ui/core'
import { selectSession, confirmToken, reToken } from 'reducers/session'
import Popup from 'components/ui/Popup'
import { SubmitButton } from 'components/ui/form'
import Form, { Token } from 'components/ui/form/TokenForm'
import { warnings } from 'components/ui/form/TokenForm/messages'
import TokenAction from './TokenAction'
import { AUTHENTICATORS } from 'libs/infinitepay/link'
import useRudderStack from 'utils/useRudderStack'
import ELEMENT_TEST_ID from '/playwright/support/elements'
import { usePaymentContext } from 'context/PaymentContext'

function TokenForm({ open = false, onClose }) {
  const dispatch = useDispatch()
  const session = useSelector(selectSession)
  const {rudderStack, ready: readyRudderStack} = useRudderStack();
  const {params} = usePaymentContext();
  

  const onSubmit = async ({ token }) => {
    
    const { meta, payload } = await dispatch(
      confirmToken({
        interaction_id: session.confirmation.interaction_id,
        ...session.extraData,
        token,
        rudderStack,
        params
      }),
    )
    if (meta.condition === false) {
      const msg = payload?.error || payload
      if (typeof msg == 'string' && msg.startsWith('Email ')) {
        onClose()
        return
      }
      return {
        token: payload?.error || payload,
      }
    } else {
      onClose()
    }
  }
  const message = session?.confirmation?.authenticator === AUTHENTICATORS.zap ?  warnings.form_token_zap : warnings.form_token_sms

  return (
    <Popup
      open={open}
      title={message.title}
      icon={message.icon}
      onClose={onClose}
    >
      <Box>
        <Box>{message.content}</Box>
        <Form
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            pristine,
            submitting,
            submitted,
            hasValidationErrors,
            form,
            submitError,
          }) => {
            const disabled = pristine || submitting || hasValidationErrors

            const onReToken = async (type) => {
              onClose()
              if (readyRudderStack && rudderStack) {
                  rudderStack.track("Payment Link ReToken - " + type);
              }
              const { meta, payload } = await dispatch(
                reToken({
                  ...session.extraData,
                  authenticator: type
                }),
              )
              if (meta.condition === false) {
                return {
                  token: payload?.error || payload,
                }
              }
            }
            
            return (
              <>
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box textAlign="left">
                        <Token />
                      </Box>
                      <br />
                      <TokenAction onEdit={onClose} onAction={onReToken} />
                    </Grid>
                    
                    <Grid item xs={12}>
                      <SubmitButton
                        disabled={disabled}
                        submitting={submitting && !submitted}
                        aria-invalid={hasValidationErrors}
                        data-testid={ELEMENT_TEST_ID.submitButton}
                      >
                        Enviar
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </form>
              </>
            )
          }}
        />
      </Box>
    </Popup>
  )
}

export default TokenForm
